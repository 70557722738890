@media (max-width: 1399px) {
    .feature-img {
        width: 40% !important;
        right: 50px !important;
        bottom: -40px !important;
    }



}

@media (max-width: 1199px) {

    .logo svg,
    .footer-logo>svg {
        width: 180px !important;
    }

    .nav-bar-list {
        gap: 10px !important;
    }

    .banner-content {
        gap: 0 !important;
        width: 100% !important;
        padding-top: 35px !important;
    }

    button {
        padding: 6px 15px !important;
    }

    .feature-box>div {
        width: 38% !important;
    }

    .feature-img {
        width: 40% !important;
        right: 90px !important;
    }

    .contact-row {
        padding: 70px 30px 0 !important;
    }
}

@media (max-width: 991px) {
    header {
        padding: 0 !important;
    }

    .nav-bar-list {
        display: none !important;
    }

    .toggleBtn {
        margin: 0 5px;
        display: block !important;
        cursor: pointer;
        transition: all 0.5s cubic-bezier(0.77, 0, 0.18, 1);
    }

    .toggleBtn svg {
        color: var(--themColor);
    }

    .showToggle {
        position: absolute !important;
        top: 81px;
        left: 0;
        background: var(--white) !important;
        transition: all 0.4s cubic-bezier(0.77, 0, 0.18, 1);
        color: var(--white) !important;
        width: 100% !important;
        display: block !important;
        box-shadow: 0px 5px 15px rgb(42 110 41 / 59%);
        -webkit-transition: all 0.4s cubic-bezier(0.77, 0, 0.18, 1);
        -moz-transition: all 0.4s cubic-bezier(0.77, 0, 0.18, 1);
        -ms-transition: all 0.4s cubic-bezier(0.77, 0, 0.18, 1);
        -o-transition: all 0.4s cubic-bezier(0.77, 0, 0.18, 1);
    }

    .showToggle div {
        border-bottom: 1px solid var(--themeColor);
        outline: none;
        width: 100%;
        padding: 20px 10px;
    }

    .showToggle a:hover {
        color: var(--themeColor);
    }

    .showToggle .active {
        color: var(--themeColor);
    }

    .showToggle .nav-bar-list a {
        color: var(--black);
        background: none !important;

        &:focus,
        &::before,
        &.active::before {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            width: 0;
            height: 0;
            background: transparent !important;
        }
    }

    .nav-bar-list a.active::before {
        background: transparent !important;
    }

    .sticky {
        padding: 0px 0px !important;
        z-index: 200;
    }

    // nenu-responsive end
    .space-top-100 {
        padding-top: 50px;
    }

    .banner-wrap {
        height: 100% !important;
        margin-top: 80px !important;
    }


    .banner-content {
        gap: 0 !important;
        width: 100% !important;
        padding-top: 35px !important;
    }

    .about-img {
        text-align: center !important;
    }

    .about-content-wrap {
        width: 100% !important;
        text-align: center !important;

        & div {
            justify-content: center;
        }

    }

    .about-bg>div {
        padding: 25px;
        gap: 20px;
    }

    .order-1 {
        order: 0 !important;
    }

    .service-img-end,
    .service-img-start {
        text-align: center !important;
    }

    #feature {

        & .space-bottom-100,
        .space-100 {
            gap: 60px !important;

        }
    }

    .feature-row {
        position: relative;
        height: auto !important;
    }

    .feature-box {
        flex-wrap: wrap;
    }

    .feature-box>div {
        width: 100% !important;
    }

    .mobile-view {
        display: block !important;
    }

    .feature-img,
    .desktop-view,
    .contact-img {
        display: none !important;
    }
}

@media (max-width: 768px) {

    .main-heading,
    .MainHeading {
        font-size: 35px !important;
    }

    .subHeading,
    .about-content-wrap>.cKSVes,
    .contact-wrap .jHzoaX {
        font-size: 28px !important;
    }

    .contact-text {

        font-size: 14px !important;
    }

    p {
        font-size: 15px !important;
    }

    .footer-logo {
        height: 60px !important;
    }

    .footer-content {
        gap: 0 !important;
        flex-direction: column;

        & div {
            margin: 2px 0 !important;
        }
    }
}

@media (max-width: 576px) {

    .banner-content {
        justify-content: center;
        align-items: center;
        padding-bottom: 35px !important;
        text-align: center;

        & p {
            text-align: center;
        }
    }

    .main-heading,
    .MainHeading {
        font-size: 30px !important;
    }

    .subHeading,
    .about-content-wrap>.cKSVes,
    .contact-wrap .jHzoaX {
        font-size: 24px !important;
    }

    .banner-items-2 {
        left: -20px !important;
        width: 130px;
    }

    .banner-items-1 {
        top: -10px !important;
        width: 130px;
    }

    .service-content-wrapper {
        text-align: center;

        & div {
            justify-content: center;
        }
    }

    .feature-box>div>div {
        text-align: center;
        justify-content: center;
    }

    .nav-bar button {
        display: none !important;
    }

    .banner-items-2 {
        left: 0px !important;
        width: 130px;
    }

    .banner-dash-img {
        width: 93%;
    }

    .space-bottom-100 {
        padding-bottom: 50px;
    }

    .space-100 {
        padding: 50px 0;
    }

    .service-content-wrapper>div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & .gRGRCG {
            width: 140px;
        }
    }
}

@media (max-width: 480px) {}

@media (max-width: 420px) {

    .main-heading,
    .MainHeading,
    .service-content-wrapper .hXFhlD {
        font-size: 22px !important;
    }

    .banner-items-2 {
        left: 0px !important;
        width: 100px;
    }

    .banner-items-1 {
        top: -10px !important;
        width: 100px;
    }

    .subHeading,
    .about-content-wrap>.cKSVes,
    .contact-wrap .jHzoaX,
    .service-content-wrapper>span {
        font-size: 18px !important;
    }

    .about-bg>div {
        padding: 25px 0 !important;
    }

    .contact-row {
        padding: 70px 10px 0 !important;
    }

}

/***************** RTL DIrection*************************/

@media (max-width: 1399px) {

    .rtl .feature-img {
        right: 0px !important;
    }

    

}

@media (max-width: 1199px) {

    .rtl .feature-img {
        right: -100px !important;
    }
}

@media (max-width: 991px) {}

@media (max-width: 768px) {}

@media (max-width: 576px) {}

@media (max-width: 480px) {}