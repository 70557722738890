@import "variables";
@import "fonts";
// or import scss

html {
  position: relative;
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

body {
  position: relative !important;
  font-family: var(--regular);
  font-style: normal;
  font-size: 18px;
  background-color: var(--white);
  width: 100%;
  overflow-x: hidden;
}

img {
  max-width: 100%;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  cursor: pointer;
}

a,
.btn {
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

a:focus,
.btn:focus {
  text-decoration: none;
  outline: none;
}

p {
  font-size: 15px;
  font-family: var(--regular);
  padding: 15px 0;
  margin-bottom: 0 !important;
}

*::-moz-selection {
  background: var(--themeColor);
  color: #fff;
  text-shadow: none;
}

*::-moz-selection {
  background: var(--themeColor) none repeat scroll 0 0;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: var(--themeColor);
  color: #fff;
  text-shadow: none;

}


.banner-bg {
  background: url(../images/png/banner-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;

}

.about-bg {
  background: url(../images/png/about-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.feature-bg {
  background: url(../images/png/feature-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.contact-bg {
  background: url(../images/png/contact-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.space-top-100 {
  padding: 100px 0 0;
}

.space-bottom-100 {
  padding: 0 0 100px;
}

.space-100 {
  padding: 100px 0;
}

.space-50 {
  padding: 50px 0;
}

.space-25 {
  padding: 25px 0;
}

.subHeading {
  color: var(--yellow);
}

.MainHeading {
  color: var(--themeColor);
}

.Heading-Content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.spinner {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 3px solid;
  border-color: var(--yellow) transparent;
  animation: spin 1s infinite ease-out;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.input-svg {
  [fill] {
    fill: var(--themeColor);
  }
}


//  direction RTL 


.rtl body p,
.rtl .contact-text,
.rtl span,
.rtl input::placeholder,
.rtl textarea {
  font-family: var(--arRegular) !important;
}

.rtl .nav-bar-list a,
.rtl .nav-bar-list a:hover,
.rtl .nav-bar-list a.active {
  font-family: var(--arSemiBold) !important;
}

.rtl .feature-img {
  right: -70px !important;
}

.rtl .input-field {
  border: none;
  border-radius: 8px;
  padding: 6px 45px 6px 0 !important;
  width: 100%;
}

.rtl .header-btn svg,
.rtl .input-svg,
.rtl .svg {
  transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  -o-transform: scaleX(-1);
}

.rtl .change-Btn {
  position: fixed;
  bottom: 0;
  right: auto !important;
  left: 0;
}

.rtl .contact-img {
  right: -120px !important;
  transform: scaleX(-1) !important;
  -webkit-transform: scaleX(-1) !important;
  -moz-transform: scaleX(-1) !important;
  -ms-transform: scaleX(-1) !important;
  -o-transform: scaleX(-1) !important;
}

.rtl .main-heading {
  font-size: 30px;
}

.rtl .contact-text {
  direction: ltr;
}


@import "responsive";

// @import '~video-react/styles/scss/video-react';